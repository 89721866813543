import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueApexCharts from 'vue3-apexcharts'
import { VueReCaptcha } from 'vue3-recaptcha2'
import VueGtag from 'vue-gtag'

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(VueReCaptcha, { siteKey: '6LfhA84pAAAAAJhdEQe_H6wgP9Dd5x0V54FWic26' })
    .use(VueGtag, { config: { id: 'G-6KGDL9SWB2' } })
    .mount('#app')